.my-header {
  top: 0;
  display: flex;
  height: 80px;
  width: 100%;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 120px;
  background-color: #F6F6F6;
  z-index: 4;
}

.header-sync-padding {
  padding: 0 140px;
}
@media only screen and (max-width: 500px) {
  .my-header-home-page {
    background-color: transparent !important;
  }
}

@media only screen and (max-width: 1050px) {
  .my-header {
    padding: 0 80px;
  }
  .header-sync-padding {
    padding: 0 100px;
  }
}
@media only screen and (max-width: 850px) {
  .my-header {
    padding: 0 40px;
  }
  .header-sync-padding {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 750px) {
  .my-header {
    padding: 0 24px;
  }
  .header-sync-padding {
    padding: 0 32px;
  }
}

@media only screen and (max-width: 580px) {
  .my-header {
    padding: 0 16px;
    height: 68px;
  }
  .header-sync-padding {
    padding: 0 24px;
  }
}
.my-header .logo-black {
  width: 140px;
  display: none;
}
.my-header .logo-gray {
  width: 200px;
  display: flex;
  flex-shrink: 0;
}
.my-header .logo-black img {
  width: 100%;
}
.my-header .logo-gray img {
  width: 100%;
}

@media only screen and (max-width: 850px) {
  .my-header .logo-black {
    width: 120px;
  }
}

@media only screen and (max-width: 750px) {
  .my-header .logo-black {
    width: 110px;
  }
}

@media only screen and (max-width: 580px) {
  .my-header .logo-gray {
    width: 100px;
  }
  /*.my-header .logo-black {*/
  /*  display: none;*/
  /*}*/
}
.container {
  display: flex;
  align-items: center;
}

.my-header .about {
  font: normal normal 300 21px/25px ofelia-text;
  color: #BCBCBC;
  margin-left: 16px;
  margin-top: 5px;
  text-decoration: none;
}

@media only screen and (max-width: 1050px) {
  .my-header .about {
    font-size: 20px;
    margin-left: 16px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 580px) {
  .my-header .about {
    font-size: 14px;
    margin-left: 16px;
  }
}
@media only screen and (max-width: 750px) {
  .my-header .about {
    font-size: 18px;
  }
}
.my-header .icon-container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 16px;
  border: 1px solid #BCBCBC;
}


@media only screen and (max-width: 750px) {
  .my-header .icon-container {
    width: 32px;
    height: 32px;
    margin-left: 16px;
  }
}

@media only screen and (max-width: 580px) {
  .my-header .icon-container {
    width: 22px;
    height: 22px;
    margin-left: 8px;
  }
}

.my-header .icon {
  width: 100%;
  max-height: 100%;
}

.header-support {
  height: 80px;
  margin-bottom: 24px;
  width: 100%;
}

.home-header-support {

}

@media only screen and (max-width: 580px) {
  .home-header-support {
    display: block;
    margin-bottom: 8px;
    height: 68px;
  }
}

body {
  background-color: #F6F6F6;
}
@media only screen and (max-width: 580px){

  .header-support {
    display: block;
    margin-bottom: 8px;
    height: 68px;
  }
}

/*----------------------------------------------------------------------------
---------------------------------------------------------------------------------
---------------------------------------------------------------------------------
---------------------------------------------------------------------------------
--------------------------------------Main --------------------------------------
---------------------------------------------------------------------------------
---------------------------------------------------------------------------------
---------------------------------------------------------------------------------
---------------------------------------------------------------------------------
---------------------------------------------------------------------------------
---------------------------------------------------------------------------------
 */

.main-home {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-top: 60px;
  display: none;
}

/*@media only screen and (max-width: 1050px) {*/
/*  .main-home {*/
/*    margin-top: 60px;*/
/*    padding: 0 100px;*/
/*  }*/
/*}*/
/*@media only screen and (max-height: 800px) {*/
/*  .main-home {*/
/*    margin-top: 32px;*/
/*  }*/
/*}*/
/*@media only screen and (max-width: 850px) {*/
/*  .main-home {*/
/*    padding: 0 60px;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 750px) {*/
/*  .main-home {*/
/*    padding: 0 32px;*/
/*  }*/
/*}*/

@media only screen and (max-width: 580px) {
  .main-home {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
.main-home .list {
  margin-right: 64px;
  flex-shrink: 0;
}

@media only screen and (max-width: 580px) {
  .main-home .list {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1050px) {
  .main-home .list {
    margin-right: 48px;
  }
}

@media only screen and (max-width: 580px) {
  .main-home .list {
    margin-right: 0;
  }
}
.main-home .list-item {
  font: normal normal normal 32px/40px ofelia-text;
  display: block;
  text-decoration: none;
  color: black;
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 1050px) {
  .main-home .list-item {
    font-size: 24px;
  }
}

@media only screen and (max-width: 580px) {
  .main-home .list-item {
    box-sizing: border-box;
    background-color: rgba(255,255,255,0.1);
    padding: 0 16px;
    font: normal normal normal 22px/25px ofelia-text;
    border-top: 1px solid rgba(100, 100, 100, 0.15);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 63px;
    color: #000000;
    text-shadow: 0px 3px 6px rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
  }
  .main-home .list-item:last-child {
    border-bottom: 1px solid rgba(100, 100, 100, 0.15);
  }
}

/*.main-home .list-item:hover {*/
/*  font-weight: bold;*/
/*  text-decoration: underline;*/
/*}*/

.main-home .image-container {
  display: block;
  flex-grow: 1;
  max-width: 800px;
  position: relative;
  align-self: stretch;
}

.main-home .back-image-container {
  display: none !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 580px) {
  .main-home .image-container {
    display: none;
  }

  .main-home .back-image-container {
    display: block !important;
  }
}
.main-home .image {
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
}
@media only screen and (min-width: 1400px) {
  .main-home .image-container {
    max-width: 1200px;
  }
  .main-home .image {
    top: 0;
  }
}
@media only screen and (max-width: 580px) {
  .main-home .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.my-detail-title {
  font-family: 'Cy';
  font-weight: 400;
  font-size: 36px;
  margin-bottom: 16px;
  margin-top: 40px;
}
.my-detail-content {
  font-family: ofelia-text;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
}

@media only screen and (max-width: 580px){
  .my-detail-title {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 22px;
  }
  .my-detail-content {
    font-size: 18px;
    line-height: 24px;
  }
}

.my-about-image-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.my-about-image {
  width: 382px;
  height: 382px;
  border-radius: 50%;
}

.my-about-content {
  font-family: ofelia-text;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  max-width: 700px;
  flex-grow: 1;
  min-width: 390px;
}

.my-about-contact {
  font-family: ofelia-text;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  flex-shrink: 0;
  margin-left: 36px;
}
.my-about-contact-email {
  text-decoration: none;
}
my-about-contact-email :visited {
  text-decoration: none;
  color: #222222;
}
.my-about-content-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
@media only screen and (max-width: 900px){
  .my-about-content-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .my-about-image-container {
    justify-content: center;
    margin-bottom: 16px;
  }
  .my-about-contact {
    margin-left: 0;
    margin-top: 36px;
  }
}
@media only screen and (max-width: 580px){

  .my-about-image {
    width: 116px;
    height: 116px;
  }
  .my-about-content {
    font-size: 18px;
    line-height: 28px;
    flex-grow: 0;
    min-width: 10px;
  }
  .my-about-contact {
    font-size: 18px;
    line-height: 28px;
  }
}

.my-video-container {
  margin: auto;
  margin-bottom: 24px;
}

div#nav {
  position: relative;
  margin-left: 20px;
}

.dropdown-toggle {
  user-select: none;
  color: #BCBCBC;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-icon {
  width: 32px;
  margin-right: 4px;
}

.dropdown-text {
  color: #BCBCBC;
  font: normal normal 300 21px/25px ofelia-text;
  margin-top: 5px;
}
+@media only screen and (max-width: 1050px) {
  .dropdown-text {
    font-size: 20px;
  }
}

/**** ****/
ul.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  margin-top: 5px;
  padding: 10px 10px 0;
  background: #222222;
  color: #FFFFFF;
  border-radius: 10px;
}
ul.dropdown li { list-style-type: none; white-space: nowrap; color: #FFFFFF; margin-bottom: 16px}
ul.dropdown li a { text-decoration: none; padding: 0em 1em; display: block; color: #FFFFFF; }
ul.dropdown li a:hover {
  color: #999999;
}

@media only screen and (max-width: 580px) {
  div#nav {
    display: none;
  }
}
